/* eslint-disable no-unsafe-optional-chaining */
import { flatData } from './flats_data'
import { Flat_Page_Component_Wrapper, Flat_Wrapper, Flat_Page_Wrapper } from './flat_class_Wrapped'

function getSegmentNavigation(segment: string) {
    switch (segment) {
        case 'johanna':
            return {
                text: 'Villa Johanna',
                to: '/wohnungen/johanna', ///#wohnungen
                slash: true,
            }
        case 'justina':
            return {
                text: 'Villa Justina',
                to: '/wohnungen/justina', ///#wohnungen
                slash: true,
            }
    }
}

function getContentText(segment: string, flat: Flat_Wrapper) {
    let _content_1 = ''
    const _content_2 = ['']
    _content_1 = 'Informationen zur Wohnung'

    _content_2.push(
        `Die Wohnung ${flat.flatNumber} befindet sich im ${flat.levels.wLevels} der Villa ${flat.segment.charAt(0).toUpperCase() + flat.segment.slice(1)}. Großzügige Wohnräume, hochwertige und exklusive Ausstattung und ${flat.getNumberasString(flat.rooms.baths, false)} moderne${
            flat.rooms.baths === 1 ? 's' : ''
        } Badezimmer, sorgen für hohen Wohnkomfort.`
    )

    _content_2.push(`
            Tauchen Sie ein in die traumhafte Lage dieser Wohnung, umgeben von der malerischen Schönheit der Saale und ruhigen Wohnlage. `)

    return [_content_1, _content_2.join(' ')]
}

function getAttributes(flat: Flat_Wrapper) {
    const _head = {
        title: 'Details',
        subTitle: 'Wohnung ' + flat.flatNumber + ' - Ausstattung',
    }
    const _flat = [
        String(
            flat.levels.wLevels.length > 1
                ? 'Wohnraum auf ' + flat.levels.nMain + ' Etagen'
                : 'Wohnung im ' + flat.levels.wLevels[0]
        ),

        flat.getNumberasString(flat.rooms.living, true) + ' Wohnräume',
        String('Echtholzparkett in den Schlafzimmern'),
        String(
            flat.rooms.baths > 1
                ? 'Großes und modernes Badezimmer mit bodentiefer Dusche und hochwertigen Armaturen & Gäste-WC'
                : 'Großes und modernes Badezimmer mit bodentiefer Dusche, Doppelhandwaschbecken und hochwertigen Armaturen'
        ),
        flat.outSideExtra.length > 1 && flat?.outSideExtra,
        String('Personenaufzug im gemeinsamen Treppenhaus'),
        String('Niedrige Energiekosten durch hohe Dämmung <strong>(KfW-Effizienzhaus 70)</strong>'),
        ...flat?.technical.heating,
        ...flat?.technical.venting,

        String('Fahrradstellplätze in abschließbaren Fahrradraum'),

        String('Dazu mietbare Garage mit fernsteuerbarem Sektionaltor / Stellplatz'),
    ]
    return {
        content: {
            head: _head,
            flat: _flat,
            colorScheme: flat.colorScheme,
            rent: 0,
            // rent: flat.price.main,
            availability: flat.availability,
            link: {
                to: '/kontakt?wohnung=' + flat.flatNumber,
            },
        },
    }
}

function buildHead(flat: Flat_Wrapper, segment: string) {
    return {
        title: ['Wohnung ' + flat.flatNumber],
        titleSlash: true,
        flatFont: true,
        subTitle: [flat.availability ? "Verfügbar" : "Vermietet"],
        subTitleC: [""],
        colorScheme: flat.colorScheme,
        links: [
            {
                text: 'Mietwohnungen',
                to: '/wohnungen',
                slash: false,
                slashOverride: true,
            },
            getSegmentNavigation(segment),
        ],
    }
}

function getHeadInfo(flat: Flat_Wrapper, segment: string) {
    if (flat) {
        return {
            head: buildHead(flat, segment),
            image: buildHeadImage(flat),
            content: getContentText(segment, flat),
        }
    }
}

function buildHeadImage(flat: Flat_Wrapper) {
    return flat.images.head
}

function getCarousel(flat: Flat_Wrapper) {
    return {
        flat: flat.flatNumber,
        images: flat.images.value,
    }
}

function getSthElse(flat: number, segment: string) {
    if (flat === 1) {
        const newFlat = Number(flat) + 1
        let name = 'name'
        if (flat > 0 && flat < 6) {
            name = 'img_f_johanna'
        } else if (flat < 8) {
            name = 'img_f_justina'
        }
        return {
            title: ['Nächste Wohnung'],
            links: [
                {
                    text: 'Zur Wohnung ' + newFlat,
                    link: '/wohnungen/' + segment + '/' + newFlat,
                    scrollTo: false,
                },
            ],
            image: {
                name: name,
                folder: ['imgs'],
                alt: 'Präsentation Nächste Wohnung ' + newFlat,
            },
        }
    } else if ((flat > 1 && flat < 6) || (flat > 6 && flat < 12)) {
        const newFlat = Number(flat) + 1
        const lastFlat = Number(flat) - 1
        const lastSegment = flat <= 7 ? 'johanna' : 'justina'
        let segmentUpper = ''
        let name = 'name'
        if (flat > 0 && flat < 6) {
            segmentUpper = 'Villa Johanna'
            name = 'img_f_johanna'
        } else if (flat < 12) {
            segmentUpper = 'Villa Justina'
            name = 'img_f_justina'
        }
        return {
            title: ['Das Weitere Angebot der ' + segmentUpper],
            links: [
                {
                    text: 'Nächste Wohnung ' + newFlat,
                    link: '/wohnungen/' + segment + '/' + newFlat,
                    scrollTo: false,
                },
                {
                    text: 'Vorherige Wohnung',
                    link: '/wohnungen/' + lastSegment + '/' + lastFlat,
                    scrollTo: false,
                },
            ],
            image: {
                name: name,
                folder: ['imgs'],
                alt: 'Präsentation Nächste Wohnung ' + newFlat,
            },
        }
    } else if (flat === 6) {
        const newFlat = Number(flat) + 1
        return {
            title: ['Erkunden Sie das Weitere Angebot in der Villa Justina'],
            links: [
                {
                    text: 'Nächste Wohnung ' + newFlat,
                    link: '/wohnungen/justina/' + newFlat,
                    scrollTo: false,
                },
                {
                    text: 'Informationen zur Villa Justina',
                    link: '/wohnungen/justina',
                    scrollTo: false,
                },
            ],
            image: {
                name: 'img_f_justina',
                folder: ['imgs'],
                alt: 'Präsentation der Villa Justina',
            },
        }
    } else if (flat === 12) {
        return {
            title: ['Erkunden Sie das Weitere Angebot'],
            links: [
                {
                    text: 'Erkunden Sie den Alten Fährhof',
                    link: 'https://alter-faehrhof.de/',
                    scrollTo: false,
                },
                {
                    text: 'Weitere Projekte der Region',
                    link: '/projekte',
                    scrollTo: false,
                },
            ],
            image: {
                name: 'main_5',
                folder: ['imgs', 'root'],
                project: 'faehrhof',
                alt: 'Präsentation Nächste Wohnung ' + flat,
            },
        }
    }
}

function getBlueprint(flat: Flat_Wrapper, segment: string) {
    const bpText = getBlueprintText(flat)
    const bpImages = getBlueprintImages(flat.flatNumber, segment)
    const data = {
        head: bpText.head,
        content: bpText.content,
        colorScheme: flat.colorScheme,
        button: bpText.button,
        levels: bpText.levels,
        images: bpImages?.images,
    }
    return data
}

function getBlueprintText(flat: Flat_Wrapper) {
    const levels = flat.levels.wLevels
    const _content = new Map<number, string[]>()
    _content.set(0, [])
    _content.set(1, [])

    let _levels = []
    let _head = ''
    let _button = undefined

    _head = 'Beschreibung der Wohnung'

    _content.get(0)?.push(' ' + flat.cDescrView[0])

    _levels = flat.levels.wLevels

    return {
        head: {
            title: _head,
            flat: flat.flatNumber,
        },
        content: _content,
        levels: _levels,
        button: _button,
    }
}

function getBlueprintImages(flat: number, segment: string) {
    return {
        images: [
            {
                name: 'blueprint',
                folder: ['imgs', 'flats', 'we_' + flat],
                alt: 'Grundriss Wohnung ' + flat,
            },
        ],
    }
}

export function getFlatInfoWrapped(data: Flat_Page_Wrapper) {
    const flat = flatData.filter((item) => item.flatNumber === data.flat)[0]
    if (flat === undefined) return {}
    if ((data.flat > 0 && data.flat <= 6 && data.segment === 'johanna') || (data.flat > 6 && data.flat <= 12 && data.segment === 'justina')) {
        switch (data.component) {
            case Flat_Page_Component_Wrapper.HeadInfo:
                return getHeadInfo(flat, data.segment)
            case Flat_Page_Component_Wrapper.Carousel:
                return getCarousel(flat)
            case Flat_Page_Component_Wrapper.SthElse:
                return getSthElse(flat.flatNumber, data.segment)
            case Flat_Page_Component_Wrapper.Attributes:
                return getAttributes(flat)
            case Flat_Page_Component_Wrapper.Blueprint:
                return getBlueprint(flat, data.segment)
        }
    }
}
